<template>
  <div>
    <div class="col entry-div">
      <b-card v-if="liveEntry" class="entry-card">
        <b-row class="justify-content-around align-content-center entry-row mb-1">
          <b-button v-if="!this.interacted" variant="primary" class="entry-button"
            @click="sendID(exercise.session - 1, 'live')">
            <feather-icon size="5x" icon="EditIcon"></feather-icon>
            <!-- <span>Manuel Giris</span> -->
          </b-button>
          <b-button class="entry-button" :class="{ 'w-100': this.interacted }"
            :variant="subjectId ? 'outline-primary' : 'secondary'" :to="{
              name: 'exercises-detail',
              params: {
                id: subjectId,
              },
            }">
            <feather-icon size="5x" icon="UserIcon"></feather-icon>
            <p>{{ $t("Subject") }}</p>
          </b-button>
        </b-row>
      </b-card>
      <b-card v-if="liveEntry" class="entry-card">
        <b-row class="justify-content-around entry-row">
          <b-button v-ripple.400="'rgba(0, 255, 200, 0.15)'" class="entry-button"
            :variant="this.timerState ? 'primary' : 'secondary'" @click="timerClick()">
            <feather-icon :icon="this.timerState ? 'PauseIcon' : 'PlayIcon'" size="1.5x"
              style="height: 100%; width: 25%; margin-left: 5px" />
            <p style="width: 75%">
              {{ this.minute < 10 ? "0" + this.minute : this.minute }} :
              {{ this.second < 10 ? "0" + this.second : this.second }}
            </p>
          </b-button>
          <b-button v-ripple.400="'rgba(200,80,80,0.25)'" class="entry-button" variant="danger" @click="mistakeClick()">
            <feather-icon icon="XCircleIcon" size="1.5x" style="height: 100%; width: 25%; margin-left: 5px" />
            <p style="width: 75%">
              {{ this.mistake }}
            </p>
          </b-button>
        </b-row>
        <b-row class="justify-content-around mt-2" v-if="!this.interacted"> </b-row>
        <b-row v-if="this.showResult" class="justify-content-around entry-row">
          <p class="grade-p">{{ $t("Time Grade") }}: {{ this.timeGrade }}</p>
          <p class="grade-p">{{ $t("Mistake Grade") }}: {{ this.mistakeGrade }}</p>
        </b-row>
        <b-row v-if="this.showResult" class="justify-content-around submit-button">
          <b-button v-ripple.400="'rgba(60,240,120,0.25)'" class="entry-button" variant="secondary"
            @click="resetExercise()">
            <feather-icon icon="RefreshCwIcon" size="1.5x" />
          </b-button>
          <b-button v-ripple.400="'rgba(60,120,240,0.25)'" class="entry-button" variant="primary" @click="sendExercise()">
            <feather-icon icon="SendIcon" size="1.5x" />
          </b-button>
        </b-row>
        <!-- <b-row class="mt-1" align-h="center">
        <b-col>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="selectClick(2)"
            ><span class="text-nowrap">Egzersiz Listesine Git</span></b-button
          >
        </b-col>
      </b-row> -->
      </b-card>
      <b-card class="entry-card">
        <b-row class="justify-content-around entry-row">
          <b-button v-if="!this.interacted" :variant="prevExerc && !prevExerc.solved ? 'outline-primary' : 'secondary'"
            @click="findPrevUnsolvedExercise()" class="custom-nav-button">
            <feather-icon size="6x" icon="SkipBackIcon"></feather-icon>
          </b-button>
          <b-button :to="{
              name: 'print-exercise',
              params: { id: 0, amountp: 0 },
              query: { reprint: true, exercise: exerciseuID, answer: true },
            }" target="_blank" variant="outline-info" class="custom-nav-button" :class="{ 'w-100': this.interacted }">
            <feather-icon size="6x" icon="KeyIcon"></feather-icon>
          </b-button>
          <b-button v-if="!this.interacted" class="custom-nav-button" :variant="
              nextExerc != (null || undefined) && !nextExerc.solved
                ? 'outline-primary'
                : 'secondary'
            " @click="
              nextExerc && !nextExerc.solved;
              findNextUnsolvedExercise();
            ">
            <feather-icon size="6x" icon="SkipForwardIcon"></feather-icon>
          </b-button>
        </b-row>
      </b-card>
      <!-- <feather-icon size="128" icon="FileTextIcon"> </feather-icon> -->
      <!-- <b-row>
      <b-col>
       with helper text
        <b-form-group
          label="Egzersiz No Giriniz ve Yenile Butonuna Basın"
          label-for="helperInput"
          description="Egzersiz Numarası Egzersiz Kağıdının Sağ Altında Qr Kod üzerinde Yazmaktadır."
        >
          <b-form-input id="helperInput" placeholder="Egzersiz No." />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button variant="outline-primary" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="sendID(props.row.uID, 'uID')">
          <feather-icon icon="Edit2Icon" size="12" class="text-body align-left" />
          <span class="text-nowrap uid-text">Sonuç Gir</span>
        </b-button>
      </b-col>
    </b-row>-->
    </div>
    <div class="nav-div" v-if="!this.interacted">
      <b-card class="nav-card">
        <!-- <feather-icon icon="ChevronLeft"></feather-icon> -->
        <div class="pillbox" style="max-width: 360px">
          <b-button :variant="
              index == exerciseIndex
                ? 'primary'
                : exerc.solved
                ? 'success'
                : 'outline-info'
            " pill class="pillbtn" v-for="(exerc, index) in sessionData"
            @click="!exerc.solved ? setActiveExercise(exerc.uID) : ''" :ref="exerc.uID" :id="exerc.uID" :key="exerc.uID">
            <!-- <feather-icon
            size="2x"
            icon="ChevronRightIcon"
            v-if="index == exerciseIndex"
          ></feather-icon> -->
            <feather-icon size="5x" :icon="
                index == exerciseIndex
                  ? 'ChevronsDownIcon'
                  : exerc.solved
                  ? 'CheckIcon'
                  : 'Edit3Icon'
              ">
            </feather-icon>
            <p style="height: 60%">
              {{ exerc.uID }}
            </p>
          </b-button>
        </div>
        <!-- <feather-icon icon="ChevronRight"></feather-icon> -->
      </b-card>
    </div>
    <UsersExerciseEntry ref="getref" @sendComplete="findNextUnsolvedExercise" :subjectID="subjectId" class="hidden" />
  </div>
</template>
<script>
import {
  BButtonGroup,
  BButton,
  BCard,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BLink,
} from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import UsersExerciseEntry from "@/views/apps/user/users-exercise/UsersExerciseEntry.vue";
import i18n from "@/libs/i18n";
import { VueGoodTable } from "vue-good-table";
const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");

export default {
  components: {
    BButtonGroup,
    BButton,
    BCard,
    BRow,
    BCol,
    FeatherIcon,
    BBadge,
    UsersExerciseEntry,
    BFormInput,
    BFormGroup,
    FeatherIcon,
    BLink,
    FeatherIcon,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      interacted: false,
      selectionMenu: true,
      liveEntry: true,
      sessionData: [],
      minute: 0,
      second: 0,
      timerState: false,
      timeGrade: "A",
      mistake: 0,
      mistakeGrade: "A",
      showResult: false,
      pauseCorrection: 0,
      intervalStartTime: 0,
      exerciseuID: null,
      exercise: {},
      subjectId: null,
      prevExerc: null,
      nextExerc: null,
      exerciseIndex: 0,
    };
  },
  created() {
    this.fetchSessionData();
  },
  methods: {
    findNextUnsolvedExercise() {
      if (this.nextExerc && !this.nextExerc.solved) {
        this.setActiveExercise(this.nextExerc.uID);
      } else {
        let found = false;
        for (let i = this.exerciseIndex + 1; i < this.sessionData.length; i++) {
          if (!this.sessionData[i].solved) {
            this.setActiveExercise(this.sessionData[i].uID);
            found = true;
            return true;
          }
        }
        if (!found) {
          this.findUnsolvedExercise();
        }
      }
    },
    findPrevUnsolvedExercise() {
      if (this.prevExerc && !this.prevExerc.solved) {
        this.setActiveExercise(this.prevExerc.uID);
      } else {
        let found = false;
        for (let i = this.exerciseIndex - 1; i > -1; i--) {
          if (!this.sessionData[i].solved) {
            this.setActiveExercise(this.sessionData[i].uID);
            found = true;
            return true;
          }
        }
        for (let i = this.sessionData.length - 1; i > this.exerciseIndex + 1; i--) {
          if (!this.sessionData[i].solved) {
            this.setActiveExercise(this.sessionData[i].uID);
            found = true;
            return true;
          }
        }
        if (!found) {
          this.findUnsolvedExercise();
        }
      }
    },
    findUnsolvedExercise() {
      let currentExercUnsolved = false;
      let nextUnsolved = this.sessionData.findIndex((item) => item.solved == false);
      if (nextUnsolved == this.exerciseIndex) {
        nextUnsolved = this.sessionData.findIndex(
          (item, index) => item.solved == false && index != this.exerciseIndex
        );
        currentExercUnsolved = true;
      }

      if (nextUnsolved > -1) {
        this.setActiveExercise(this.sessionData[nextUnsolved].uID);
      } else if (!currentExercUnsolved) {
        this.$swal({
          title: i18n.t("Session Result Entry Complete"),
          text: i18n.t("You are being redirected"),
          icon: "success",
          button: "OK",
          timer: 2000,
        }).then(() => {
          // console.log("redirect2 : " + JSON.stringify(this.subjectId));
          this.$router.push({
            name: "clientlist-detail",
            params: {
              id: this.subjectId,
            },
          });
        });
      } else {
        this.$swal({
          title: i18n.t("Last Exercise") + "!",
          text: i18n.t("You are on the last exercise of the session"),
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      }
    },
    fetchSessionData() {
      this.exerciseuID = this.$route.params.uID;

      /* console.log(this.prevUID);
      console.log(this.nextUID); */
      axios.get(path.liveReqs + this.exerciseuID).then((res) => {
        // console.log(res.data.exercise);
        this.exercise = res.data.exercise;
        this.subjectId = res.data.exercise.for._id;
        this.exerciseSesionId = res.data.exercise.session;
        this.sessionData = res.data.sessionData;
        this.exerciseIndex = this.sessionData.findIndex(
          (item) => item.uID === this.exerciseuID
        );
        // console.log(this.sessionData.length, this.exerciseIndex);
        this.nextExerc = this.sessionData[this.exerciseIndex + 1];
        this.prevExerc = this.sessionData[this.exerciseIndex - 1];
        if (!this.exercise.solved) {
          const scrollIndex = this.sessionData[this.exerciseIndex + 2]
            ? this.sessionData[this.exerciseIndex + 2].uID
            : this.sessionData[this.exerciseIndex + 1]
            ? this.sessionData[this.exerciseIndex + 1].uID
            : this.sessionData[this.exerciseIndex].uID;
          setTimeout(() => {
            document.getElementById(scrollIndex).scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }, 0);
        } else {
          this.$swal({
            title: i18n.t("Exercise Already Solved") + "!",
            text: i18n.t("You are being redirected to next exercise"),
            icon: "success",
            button: i18n.t("OK"),
            timer: 2000,
          }).then(() => {
            if (this.sessionData.findIndex((item) => item.solved == false) > -1) {
              this.findNextUnsolvedExercise();
            } else {
              this.$swal({
                title: i18n.t("Session Entries are Complete"),
                text: i18n.t("You are being redirected"),
                icon: "success",
                button: i18n.t("OK"),
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  name: "clientlist-detail",
                  params: {
                    id: this.subjectId,
                  },
                });
              });
            }
          });
        }
      });
    },
    setActiveExercise(uID) {
      this.exerciseuID = uID;
      this.exerciseIndex = this.sessionData.findIndex(
        (item) => item.uID === this.exerciseuID
      );
      this.exercise = this.sessionData[this.exerciseIndex];
      this.nextExerc = this.sessionData[this.exerciseIndex + 1];
      this.prevExerc = this.sessionData[this.exerciseIndex - 1];
      history.pushState({}, null, "/live-exercise-entry/" + this.exerciseuID);
      const scrollIndex = this.sessionData[this.exerciseIndex + 2]
        ? this.sessionData[this.exerciseIndex + 2].uID
        : this.sessionData[this.exerciseIndex + 1]
        ? this.sessionData[this.exerciseIndex + 1].uID
        : this.sessionData[this.exerciseIndex].uID;
      setTimeout(() => {
        document.getElementById(scrollIndex).scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 0);
      this.resetExercise();
    },
    sendID(id, seansType) {
      // console.log(id, seansType);
      this.$refs.getref.sendID(id, seansType);
    },
    selectClick(val) {
      if (val == 1) {
        this.liveEntry = true;
        this.selectionMenu = false;
      }
    },
    timerClick() {
      this.interacted = true;
      this.timerState = !this.timerState;
      this.showResult =
        this.timeGrade != null && this.mistakeGrade != null && !this.timerState;
      if (this.timerState) {
        this.timerStart();
      } else {
        this.timerStop();
      }
    },
    timerStart() {
      this.timerState = true;
      this.intervalStartTime = Date.now();
      this.timer = setTimeout(() => {
        // call timer as a timeout to adjust first invoke time by pause correction without changing 1 second interval timer.
        this.intervalStartTime = Date.now();
        if (this.second == 59) {
          this.minute++;
          this.second = 0;
          this.timeGrade = this.checkGrade(this.minute);
        } else {
          this.second++;
        }

        this.timer = setInterval(() => {
          // when timeout is called, timeout becomes an interval instead.
          this.intervalStartTime = Date.now();
          if (this.second == 59) {
            this.minute++;
            this.second = 0;
            this.timeGrade = this.checkGrade(this.minute);
          } else {
            this.second++;
          }
        }, 1000);
      }, 1000 - this.pauseCorrection);
    },
    timerStop() {
      this.timerState = false;
      this.pauseCorrection = Date.now() - this.intervalStartTime;
      clearTimeout(this.timer);
      clearInterval(this.timer);
    },
    checkGrade(min) {
      if (min < 2) {
        return "B";
      }
      if (min < 3) {
        return "C";
      }
      if (min < 4) {
        return "D";
      }
      if (min < 5) {
        return "E";
      }
      return "F";
    },
    mistakeClick() {
      if (!this.timerState) return;
      this.mistake++;
      this.mistakeGrade = this.checkGrade(this.mistake);
    },
    sendExercise() {
      if (this.timeGrade == null || this.mistakeGrade == null || this.timerState) return;
      console.log("send exercise", this.timeGrade, this.mistakeGrade);
      axios
        .post(`${path.result + this.exerciseuID}`, {
          time: this.timeGrade,
          mistake: this.mistakeGrade,
        })
        .then((res) => {
          this.sessionData[this.exerciseIndex] = res.data;
          this.findNextUnsolvedExercise();
        });
      this.$swal({
        title: i18n.t("Exercise Results are Sent"),
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    resetExercise() {
      this.timerStop();
      this.interacted = false;
      this.mistake = 0;
      this.mistakeGrade = "A";
      this.timeGrade = "A";
      this.minute = 0;
      this.second = 0;
      this.pauseCorrection = 0;
      this.showResult = false;
      const scrollIndex = this.sessionData[this.exerciseIndex + 2]
        ? this.sessionData[this.exerciseIndex + 2].uID
        : this.sessionData[this.exerciseIndex + 1]
        ? this.sessionData[this.exerciseIndex + 1].uID
        : this.sessionData[this.exerciseIndex].uID;
      setTimeout(() => {
        document.getElementById(scrollIndex).scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 0);
    },
  },
};
</script>
<!--<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
/* .seans-header {
  margin-left: 10px;
} */
.uid-text {
  color: #fff !important;
}
</style> -->
<style scoped>
.entry-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0;
  font-size: large;
  font-weight: bold;
}

svg {
  height: 100%;
  width: 25%;
}

.entry-card {
  width: 100%;
  min-width: 270px;
  max-width: 400px;
}

.nav-div {
  padding-right: 1rem;
  padding-left: 1rem;
}

.nav-card {
  min-width: 270px;
  max-width: 400px;
  margin: auto;
}

.entry-button {
  max-width: 150px;
  font-size: 1.5rem;
  width: 45%;
  height: 50px;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  padding: 0;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}

.grade-p {
  min-width: 129px;
}

.entry-row {
  width: 100%;
  margin: 5px;
}

.submit-button {
  width: 100%;
  margin: 5px;
  margin-top: 25px;
}

.pillbox {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 1rem;
}

.pillbox span {
  cursor: pointer;
  display: inline-block;
  /* *display: inline;
  *zoom: 1; */
  vertical-align: top;
}

.sidescrollwrapper {
  margin: auto;
  /* text-align: center; */
}

.buttonLink {
  text-decoration: none;
  color: #fff;
}

.pillbtn {
  padding: 0;
  margin: 0.5rem;
  width: 50px;
}

.custom-nav-button {
  /* padding: 0.4rem;
  margin: 0.2rem;
  width: 30%; */
  max-width: 150px;
  font-size: 1.5rem;
  width: 30%;
  height: 50px;
  /* flex-wrap: wrap; */
  padding: 0;
}
</style>
